.App {
  text-align: center;
}
.canvas {
  min-height: 820px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  width: 85%;
  border: 5px solid red;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffff0014;
  border-radius: 10%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.formContainer {
  width: 300px;
  margin: 120px;
  height: 320px;
  display: table;
  position: relative;
  border: 3px solid orange;
  background-color: white;
  border-radius: 8%;
  -webkit-box-shadow: 17px 25px 61px -38px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 17px 25px 61px -38px rgba(0, 0, 0, 0.75);
  box-shadow: 17px 25px 61px -38px rgba(0, 0, 0, 0.75);
}
.formContainer::selection{
  border:none;
}
.basket {
  width: 250px;

  margin-left: auto;
  margin-right: auto;
  min-height: 100px;
  border: 1px dotted red;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.relationMsg {
  z-index: 100;
  height: 55px;
  width: 130px;
  font-size: 14px;
  background-color: white;
  border: 1px solid black;
  border-radius: 15%;
  overflow-y: auto;
  z-index: 1;
}
.connectComponentFormBody {
  display: flex;
  flex-wrap: wrap;
}
.menuItem {
  background-color: whitesmoke;
  padding: 10px;
  border-bottom: 0.1px solid gray;
  cursor: pointer;
  font-family: 800;
}
textarea.text-center.rounded-pill {
  width: 200px;
}

.crossBtn{
  position: absolute;
    background: red;
    color: white;
    top: -10px;
    right: -10px;
    border-radius: 50%;
}